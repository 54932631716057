import React from 'react';
import { formatDateGMTZero } from 'utils/formatTime';
import { SignSvg, UserSignSvg } from 'components/Icon';
import { SIGNATURE_STATUS } from 'constants';
import { Button } from 'antd';

const Sign = ({
    signInfo,
    onSignClick,
    onChangeSigner,
    onShowMore,
    enable }) => {

    const SignatureImage = () => {
        if (signInfo.signed === SIGNATURE_STATUS.Signed)
            return (
                <div className='sign-image-responsive completed'>
                    <img className='img' src={`${signInfo.signaturePath}`} alt="" />
                </div>
            );
        if (signInfo.allowSign && signInfo.isNextSign)
            return (
                <div className='sign-image-responsive sign-image-signing'>
                    <div className='img d-flex align-items-center justify-content-center'>
                        <SignSvg />
                        <span>Ký</span>
                    </div>
                </div>
            );
        if (signInfo.allowSign && !signInfo.isNextSign) {
            return (
                <div className='sign-image-responsive'>
                    <div className='img d-flex align-items-center justify-content-center'>
                        <span>Chưa ký</span>
                    </div>
                </div>
            );
        }
        if (!signInfo.required)
            return (
                <div className='sign-image-responsive no-need-to-sign'>
                    <div className='img d-flex align-items-center justify-content-center'>
                        <span>Không yêu cầu ký</span>
                    </div>
                </div>
            );
        return (
            <div className='sign-image-responsive'>
                <div className='img d-flex align-items-center justify-content-center'>
                    <span>Chưa ký</span>
                </div>
            </div>
        );
    };

    return (
        <div className='sign-info d-flex flex-column align-items-center text-center'>
            <div className='sign-image' onClick={onSignClick}>
                <SignatureImage />
            </div>
            {signInfo.signed === SIGNATURE_STATUS.Signed ? (
                <>
                    <div className="user-singed">
                        {signInfo.signerName}
                    </div>
                    {signInfo.signedOnUtc && <div className="date-signed">
                        {formatDateGMTZero(signInfo.signedOnUtc, 'DD-MM-YYYY HH:mm')}
                    </div>}
                </>
            ) : null}
            {signInfo.signed === SIGNATURE_STATUS.Unsigned && signInfo.specificSigner && !signInfo.signerId ? (
                <div className='user-add'>
                    <Button
                        shape="circle"
                        size='small'
                        icon={<UserSignSvg />}
                        style={{ width: 28, height: 28, minWidth: 28 }}
                        onClick={onChangeSigner}>
                    </Button>
                </div>
            ) : null}
            {signInfo.signed === SIGNATURE_STATUS.Unsigned && signInfo.signerId ? (
                <div className='user-add'>
                    <div className="user-singed" style={{ cursor: "pointer" }} onClick={onChangeSigner}>
                        {signInfo.signerName}
                    </div>
                </div>
            ) : null}
            {signInfo.signed === SIGNATURE_STATUS.Declined ? (
                <div className='user-add' >
                    <div className="user-singed" style={{ cursor: "pointer" }}>
                        {signInfo.signerName}
                    </div>
                    <div style={{
                        fontSize: 10,
                        lineHeight: '14px',
                        color: '#8D8D8D'
                    }}>
                        {signInfo.signerName} đã từ chối ký vào {formatDateGMTZero(signInfo.rejectOnUtc, 'DD-MM-YYYY HH:mm')}. Lý do:...
                        <span style={{ color: '#1B78D4', cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation(); onShowMore(signInfo.reason);
                            }}>
                            Xem thêm
                        </span>
                    </div>
                </div>
            ) : null}
        </div >
    );
}

export default Sign;